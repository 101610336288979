import { setItem, getItem } from './Memory';
import { isDev } from './Env';

const PERSONALISATION_KEY = 'rademar-cart-token';

export const setPersonalisationKey = (key: string) => {
  setItem(PERSONALISATION_KEY, key);
};

export const getPersonalisationKey = () => {
  const key = getItem(PERSONALISATION_KEY);

  // Add dev suffix to personalisation key to avoid mixing
  // production environment data with dev.
  if (key && isDev()) {
    return `${key}-dev`;
  }

  return key;
};
