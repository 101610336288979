import React, { useEffect, useState, useRef } from 'react';
import MainMenuInfo from './MainMenuInfo';
import Search2 from '../Header/Search2';
import ProfileButton from '../Profile/ProfileButton';
import FavouriteButton from '../Favourite/FavouriteButton';
import MiniCart from '../Header/MiniCart';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import MainMenuItemHoverable from './MainMenuItemHoverable';
import MainMenuItemClickable from './MainMenuItemClickable';
import LocalizedLink from '../LocalizedLink';
import LogoBig from '../../images/rademar_green.svg';
import Logo from '../../images/rademar_logo_touch.svg';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Translate from '../Translate/Translate';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { gql, useQuery } from '@apollo/client';
import Loader from '../Loader/Loader';
import { Language } from '../../stores/Translations';
import { translations } from '../../stores';

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      title
      slug
      subCategories {
        id
        title
        slug
      }
      generalTitle
      hasSubCategories
    }
  }
`;

type CategoriesItem = {
  id: string;
  title: string;
  slug: string;
  subCategories: {
    id: string;
    title: string;
    slug: string;
  }[];
  generalTitle: string;
  hasSubCategories: boolean;
};

const MainMenu = () => {
  const mainMenuRef = useRef(null);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const { loading, error, data } = useQuery<{
    categories: CategoriesItem[];
  }>(GET_CATEGORIES);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (mainMenuRef?.current) {
      mainMenuRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const scrollLock = () => {
    document.querySelector('body').classList.toggle('scroll-lock');
  };

  const removeScrollLock = () => {
    document.querySelector('body').classList.remove('scroll-lock');
  };

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return <div>Error: {error.message}</div>;
  }

  const currentLanguage = translations.language;

  return (
    <div className="bg-white">
      <div className="hidden lg:block lg:whitespace-nowrap">
        <MainMenuInfo />
      </div>

      <div className="flex flex-row lg:pb-2 lg:flex-col relative max-w-screen-3xl mx-auto lg:px-7">
        <div className="flex items-center lg:border-b lg:border-rgrey-lightborder lg:border-solid">
          <div className="lg:pl-0 lg:pr-1 lg:py-3.5">
            <LocalizedLink
              to="/"
              onClick={() => {
                setIsMobileMenuVisible(false);
                scrollToTop();
                removeScrollLock();
              }}
              className="flex justify-center"
            >
              <img
                src={LogoBig}
                className="hidden lg:block w-8 h-8 min-w-36"
                alt="Rademar"
              />
              <img
                src={Logo}
                className="lg:hidden w-8 h-8 min-w-16"
                alt="Rademar"
              />
            </LocalizedLink>
          </div>
          <div className="hidden lg:flex py-2 whitespace-nowrap">
            {data.categories.map((category) => (
              <MainMenuItemHoverable
                key={category.id}
                title={category.title}
                id={category.id}
                slug={category.slug}
                subCategories={category.subCategories}
              />
            ))}
          </div>
          <div className="hidden lg:flex py-2">
            <div className="p-3 mt-1">
              <LocalizedLink
                className="pb-1 border-b-2 border-transparent hover:border-rgreen"
                to="/brandid"
              >
                <span className="uppercase relative font-axi-bold">
                  <Translate component="footer" keyword="brands" />
                </span>
              </LocalizedLink>
            </div>
            <div className="p-3 mt-1 hidden xl:block">
              <LocalizedLink
                className="pb-1 border-b-2 border-transparent hover:border-rgreen"
                to="/tule-toole"
              >
                <span className="uppercase relative font-axi-bold whitespace-nowrap">
                  <Translate component="footer" keyword="jobs" />
                </span>
              </LocalizedLink>
            </div>
            {currentLanguage === Language.RU ? null : (
              <div className="p-3 mt-1 hidden xl:block">
                <LocalizedLink
                  className="pb-1 border-b-2 border-transparent hover:border-rgreen"
                  to="/blogi"
                >
                  <span className="uppercase relative font-axi-bold">
                    <Translate component="menu" keyword="blogi" />
                  </span>
                </LocalizedLink>
              </div>
            )}
          </div>
          <div className="hidden lg:block py-3 mt-1 ml-auto">
            <LocalizedLink to="/kauplused">
              <span className="underline text-xs">
                <Translate component="header" keyword="stores" />
              </span>
            </LocalizedLink>
          </div>
        </div>

        <div className="flex w-full lg:py-2.5 lg:border-b lg:border-rgrey-lightborder lg:border-solid lg:justify-between">
          <div className="w-full lg:w-2/5">
            <Search2 />
          </div>
          <div className="flex ml-auto">
            <div className="hidden lg:block mx-2">
              <ProfileButton />
            </div>
            <div className="hidden lg:block mx-2">
              <FavouriteButton />
            </div>
            <div className="lg:mx-2 min-w-16">
              <MiniCart />
            </div>
            <div className="hidden lg:block ml-2">
              <LanguageSelect />
            </div>
            <div className="block lg:hidden">
              <button
                className="bg-black h-full w-full"
                onClick={() => {
                  setIsMobileMenuVisible(!isMobileMenuVisible);
                  scrollLock();
                }}
              >
                {isMobileMenuVisible ? (
                  <XMarkIcon className="h-10 w-10 text-white min-w-16" />
                ) : (
                  <Bars3Icon className="h-10 w-10 text-white min-w-16" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isMobileMenuVisible ? (
        <div
          ref={mainMenuRef}
          className="fixed lg:hidden top-[60px] left-0 right-0 bottom-0 bg-white overflow-y-scroll overscroll-contain"
        >
          <div className="relative">
            <MainMenuInfo />

            <div className="px-4">
              <div className="py-7">
                <p className="text-rgrey font-axi-bold text-sm pb-2.5">
                  <Translate component="header" keyword="choose-language" />
                </p>
                <LanguageSelect />
              </div>

              <div className="flex my-0 -m-1 pb-5">
                <div className="flex w-full">
                  <div className="basis-1/2 m-1">
                    <FavouriteButton
                      closeMobileMenu={() => {
                        setIsMobileMenuVisible(false);
                        scrollToTop();
                        removeScrollLock();
                      }}
                    />
                  </div>
                  <div className="basis-1/2 m-1">
                    <ProfileButton
                      closeMobileMenu={() => {
                        setIsMobileMenuVisible(false);
                        scrollToTop();
                        removeScrollLock();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                {data.categories.map((category) => (
                  <MainMenuItemClickable
                    key={category.id}
                    title={category.title}
                    id={category.id}
                    slug={category.slug}
                    subCategories={category.subCategories}
                    generalTitle={category.generalTitle}
                    onClick={() => {
                      setIsMobileMenuVisible(!isMobileMenuVisible);
                      scrollToTop();
                      scrollLock();
                    }}
                    onPathChange={() => {
                      scrollToTop();
                    }}
                  />
                ))}
              </div>
              <div className="flex flex-col">
                <div className="flex cursor-pointer border rounded mt-2.5 border-solid border-rgrey-light">
                  <LocalizedLink
                    className="flex flex-nowrap uppercase leading-7 flex-1 px-4 py-3.5 font-axi-bold text-start justify-between items-center"
                    to="/brandid"
                    onClick={() => {
                      setIsMobileMenuVisible(!isMobileMenuVisible);
                      removeScrollLock();
                    }}
                  >
                    <span className="uppercase relative font-axi-bold">
                      <Translate component="footer" keyword="brands" />
                    </span>
                  </LocalizedLink>
                </div>
                <div className="flex cursor-pointer border rounded mt-2.5 border-solid border-rgrey-light">
                  <LocalizedLink
                    to="/kauplused"
                    onClick={() => {
                      setIsMobileMenuVisible(!isMobileMenuVisible);
                      removeScrollLock();
                    }}
                    className="flex flex-nowrap uppercase leading-7 flex-1 px-4 py-3.5 font-axi-bold text-start justify-between items-center"
                  >
                    <span className="uppercase relative font-axi-bold">
                      <Translate component="header" keyword="stores" />
                    </span>
                  </LocalizedLink>
                </div>
                <div className="flex cursor-pointer border rounded mt-2.5 border-solid border-rgrey-light">
                  <LocalizedLink
                    className="flex flex-nowrap uppercase leading-7 flex-1 px-4 py-3.5 font-axi-bold text-start justify-between items-center"
                    to="/tule-toole"
                    onClick={() => {
                      setIsMobileMenuVisible(!isMobileMenuVisible);
                      removeScrollLock();
                    }}
                  >
                    <span className="uppercase relative font-axi-bold whitespace-nowrap">
                      <Translate component="footer" keyword="jobs" />
                    </span>
                  </LocalizedLink>
                </div>
                {currentLanguage === Language.RU ? null : (
                  <div className="flex cursor-pointer border rounded mt-2.5 border-solid border-rgrey-light">
                    <LocalizedLink
                      className="flex flex-nowrap uppercase leading-7 flex-1 px-4 py-3.5 font-axi-bold text-start justify-between items-center"
                      to="/blogi"
                      onClick={() => {
                        setIsMobileMenuVisible(!isMobileMenuVisible);
                        removeScrollLock();
                      }}
                    >
                      <span className="uppercase relative font-axi-bold">
                        <Translate component="menu" keyword="blogi" />
                        <span className="text-white text-xs uppercase bg-rgreen relative ml-1.5 px-1.5 rounded-full">
                          <Translate
                            component="new-notification"
                            keyword="notification"
                          />
                        </span>
                      </span>
                    </LocalizedLink>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col bg-rgrey-lightlight rounded mt-6 mb-20 pl-4 py-5">
              <p className="text-rgrey text-sm pb-2.5 font-axi-bold">
                <Translate component="header" keyword="customer-support" />
              </p>
              <a
                href="tel:+3726154138"
                className="text-lg leading-7 font-axi-bold"
              >
                <Translate component="footer" keyword="support-phone" />
              </a>
              <span className="text-lg leading-7 font-axi-bold">
                <Translate component="footer" keyword="support-hours" />
              </span>
              <a
                href="mailto:epood@rademar.ee"
                className="text-lg leading-7 font-axi-bold"
              >
                <Translate component="footer" keyword="support-email" />
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MainMenu;
