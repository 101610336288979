import React from 'react';
import Stroke from '../../images/underline-stroke.svg';

export default function ClickAndTryModal() {
  return (
    <div className="md:min-w-96 m-5">
      <h4 className="text-2xl leading-relaxed font-bold -mt-5 mb-10 mx-auto text-center">
        Telli tooted koju{' '}
        <div className="flex justify-center">
          <p>proovimiseks </p>
          <div className="ml-2 text-rgreen">
            <div className="inline-block relative">
              <p className="inline-block">TASUTA</p>
              <img
                src={Stroke}
                alt="underline brush stroke"
                className="absolute inset-x-0 w-full -mt-1.5"
              />
            </div>
          </div>
        </div>
      </h4>
      <ul role="list" className="space-y-4 mx-auto">
        <li className="overflow-hidden items-center">
          <div>
            <p className="text-xl leading-8 font-axi-bold mb-2">
              1. Vali tooted
            </p>
            <p className="text-sm font-axi-book">
              Vali e-poest välja kuni viis toodet ja telli need koju TASUTA
              proovimiseks,{' '}
              <span className="font-axi-bold">v.a. jalgrattad.</span> Tarne
              toimub tavapärase pakiautomaatide ja kullerteenuse hinnakirja
              alusel.
            </p>
          </div>
        </li>
        <li className="overflow-hidden items-center">
          <div>
            <p className="text-xl leading-8 font-axi-bold mb-2">
              2. Vormista tellimus
            </p>
            <p className="text-sm font-axi-book">
              Tellimuse vormistamine käib vastavalt tavapärasele e-poe
              tellimusele. Vali proovimiseks välja tooted, lisa need ostukorvi,
              täida kontaktandmete väljad ja{' '}
              <span className="font-axi-bold">
                makseviiside juurest vali &quot;TELLI JA PROOVI TASUTA&quot;.
              </span>
            </p>
          </div>
        </li>
        <li className="overflow-hidden items-center">
          <div>
            <p className="text-xl leading-8 font-axi-bold mb-2">
              3. Kinnitamine
            </p>
            <p className="text-sm font-axi-book">
              Enda isiku tuvastamise ja ostu kinnitamise võimalused:{' '}
              <span className="font-axi-bold">
                Smart-ID, Mobiil-ID või ID-kaart.
              </span>
            </p>
          </div>
        </li>
        <li className="overflow-hidden items-center">
          <div>
            <p className="text-xl leading-8 font-axi-bold mb-2">
              4. Teavitus paki saabumisest
            </p>
            <p className="text-sm font-axi-book">
              Teavituse paki saabumisest saadame{' '}
              <span className="font-axi-bold">e-posti </span>või{' '}
              <span className="font-axi-bold">SMS-i</span>
              teel. Proovimiseks ja mittesobivate toodete tagastamiseks on
              alates kauba kättesaamisest aega 14 päeva.
            </p>
          </div>
        </li>
        <li className="overflow-hidden items-center">
          <div>
            <p className="text-xl leading-8 font-axi-bold mb-2">5. Maksmine</p>
            <p className="text-sm font-axi-book">
              Pärast 14 päeva möödumist koostatakse arve nende toodete eest,
              mida te ei ole tagastanud. Maksmiseks arvestatakse 30 päeva alates
              e-poes tellimuse vormistamisest. Makseinfo ja meeldetuletused
              edastatakse e-posti ja SMS-i teel.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}
