import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { gql, useQuery } from '@apollo/client';
import Loader from '../../Loader/Loader';
// import LocalizedLink from '../../LocalizedLink';
import Translate from '../../../components/Translate/Translate';

const GET_SHOPS = gql`
  query PublicShops {
    publicShops {
      id
      title
      slug
      city
      info
      isOutlet
      citySlug
      hasMaintenance
    }
    publicShopCities
  }
`;
type ShopItem = {
  id: string;
  title: string;
  slug: string;
  city: string;
  info: string;
  citySlug: string;
  hasMaintenance: boolean;
};

const Shops = () => {
  const [selectedCity, setSelectedCity] = useState('tallinn');
  const { loading, error, data } = useQuery<{
    publicShops: ShopItem[];
    publicShopCities;
  }>(GET_SHOPS);

  const shops = data?.publicShops || [];
  const cities = data?.publicShopCities;
  const selectedShops = shops.filter((shop) => shop.citySlug === selectedCity);

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="m-5">Error: {error.message}</div>;
  }

  return (
    <div className="md:mt-20 mx-auto text-center">
      <h4 className="text-4xl leading-10 font-bold my-5">
        <Translate component="header" keyword="stores" />
      </h4>
      <div className="">
        {/* <p className="text-lg leading-7 font-normal">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit.
        </p> */}
        {cities.map((city, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedCity(city);
            }}
            className={`font-axi-bold py-3 px-6 rounded-full md:mt-10 mt-5 mx-2 hover:bg-rgrey-light ${selectedCity === city ? 'bg-rgrey-light' : 'bg-rgrey-lightlight'}`}
          >
            <Translate component="city" keyword={city} />
          </button>
        ))}
      </div>

      {selectedShops.map((shop, index) => {
        const storeInfo = JSON.parse(shop.info);
        let storeInfoContent = null;

        if (storeInfo) {
          storeInfoContent = Object.entries(storeInfo).map((key, index) => {
            return (
              <div key={index} className="flex gap-4 items-center mt-2">
                <p className="text-black font-axi-bold">{key[0]}:</p>
                <span className="text-base leading-7 text-gray-600">
                  {key[1] as string}
                </span>
              </div>
            );
          });
        }
        const openGoogleMaps = (location) => {
          const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location)}`;
          window.open(mapsUrl, '_blank');
        };
        return (
          <div className="mx-auto divide-y max-w-4xl" key={index}>
            <dl className="mt-5 ">
              {index === 0 ? (
                <Disclosure as="div" key={shop.id} className="pt-6" defaultOpen>
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-center justify-between text-left text-gray-900 border-b">
                          <span className="text-xl leading-7 font-axi-bold py-3">
                            {shop.title}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <ChevronUpIcon
                                className="h-10 w-10"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-10 w-10"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <div className="grid grid-flow-row auto-rows-max">
                          <div>{storeInfoContent}</div>
                          <div className="md:flex md:gap-2">
                            {/* {shop.hasMaintenance ? (
                            <LocalizedLink to="/rattahooldus">
                              <div className="text-white bg-rgreen rounded w-40 py-3 text-center mt-5 hover:ring-2 hover:ring-green-600">
                                <Translate
                                  component="bike"
                                  keyword="maintenance"
                                />
                              </div>
                            </LocalizedLink>
                          ) : null} */}

                            <div
                              className="rounded w-40 py-3 text-center mt-5 border hover:text-white hover:bg-black cursor-pointer"
                              onClick={() =>
                                openGoogleMaps(
                                  storeInfo?.Aadress + ',' + shop.city,
                                )
                              }
                            >
                              <Translate component="map" keyword="open" />
                            </div>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ) : (
                <Disclosure as="div" key={shop.id} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-center justify-between text-left text-gray-900 border-b">
                          <span className="text-xl leading-7 font-axi-bold py-3">
                            {shop.title}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <ChevronUpIcon
                                className="h-10 w-10"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-10 w-10"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <div className="grid grid-flow-row auto-rows-max">
                          <div>{storeInfoContent}</div>
                          <div className="md:flex md:gap-2">
                            {/* {shop.hasMaintenance ? (
                            <LocalizedLink to="/rattahooldus">
                              <div className="text-white bg-rgreen rounded w-40 py-3 text-center mt-5 hover:ring-2 hover:ring-green-600">
                                <Translate
                                  component="bike"
                                  keyword="maintenance"
                                />
                              </div>
                            </LocalizedLink>
                          ) : null} */}

                            <div
                              className="rounded w-40 py-3 text-center mt-5 border hover:text-white hover:bg-black cursor-pointer"
                              onClick={() =>
                                openGoogleMaps(
                                  storeInfo?.Aadress + ',' + shop.city,
                                )
                              }
                            >
                              <Translate component="map" keyword="open" />
                            </div>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}
            </dl>
          </div>
        );
      })}
    </div>
  );
};
export default Shops;
