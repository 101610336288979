import Ajax from '../services/Ajax';

export default class LandingPageStore {
  async loadBySlug(slug: string) {
    const { data } = await Ajax.get(`landing/${slug}`);
    return data;
  }

  async loadBySlugAndCategory(slug: string, category_id: number) {
    const { data } = await Ajax.get(`landing/${slug}/${category_id}`);

    return data;
  }
}
