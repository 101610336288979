import Ajax from './Ajax';

const analyticsRequest = async (url: string, params = {}) => {
  try {
    await Ajax.get(url, { params });
  } catch (e) {
    // Analytics requests can fail quietly.
  }
};

export const viewProduct = (productId: number) => {
  analyticsRequest(`a/view_product/${productId}`);
};

export const addVariationToCart = async (variationCode: string) => {
  analyticsRequest(`a/add_variation_to_cart/${variationCode}`);
};

export const removeVariationFromCart = async (variationCode: string) => {
  analyticsRequest(`a/remove_variation_from_cart/${variationCode}`);
};

export const search = async (categorySlug: string, brands?: string) => {
  analyticsRequest(`a/search/${categorySlug}`, { brands });
};

export const getGaId = (): Promise<string | undefined> => {
  const getCookieValue = (name) =>
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

  const gaCookie = getCookieValue('_ga');
  if (gaCookie) {
    return Promise.resolve(gaCookie.substring(6));
  }
  return Promise.resolve(undefined);
};
