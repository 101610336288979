import { observable, action, makeObservable } from 'mobx';

import Ajax from '../services/Ajax';

export default class Pages {
  @observable list = [];
  @observable pageContent = {};

  constructor() {
    makeObservable(this);
  }

  public async loadList() {
    if (this.list.length) {
      return this.list;
    }

    const { data: pages } = await Ajax.get('pages');
    this.setPages(pages);

    return this.list;
  }

  @action
  public setPages(pages) {
    this.list = pages;
  }

  public async loadContent(pageId: number) {
    if (this.pageContent[pageId]) {
      return this.pageContent[pageId];
    }

    const { data: page } = await Ajax.get(`pages/content/${pageId}`);
    this.setContent(pageId, page.content);

    return this.pageContent[pageId];
  }

  @action
  setContent(pageId: number, content: string) {
    this.pageContent[pageId] = content;
  }
}
