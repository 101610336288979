import { action, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import AuthenticationStore from '../../../stores/Authentication';
import CartStore from '../../../stores/Cart';
import UserStore from '../../../stores/User';
import ResetPassword from '../../ResetPassword';
import Translate from '../../Translate/Translate';
import Input from '../Input';
import Alert, { Type } from '../../Alert';
import './styles/index.scss';

interface Props {
  navigate: NavigateFunction;
  authentication?: AuthenticationStore;
  cart?: CartStore;
  user?: UserStore;
  toggle: () => void;
}

@inject('authentication', 'cart', 'user')
@observer
class Login extends React.Component<Props> {
  @observable message: string;
  @observable resetVisible = false;
  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.tryToAuthenticate = this.tryToAuthenticate.bind(this);
    this.setResetVisibility = this.setResetVisibility.bind(this);
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.tryToAuthenticate();
    }
  };

  async tryToAuthenticate() {
    const { authentication, cart, user, navigate, toggle } = this.props;

    if (!authentication.loginEmail && !authentication.loginPassword) {
      this.setErrorMessage('emailandpassword');
      return;
    } else if (!authentication.loginEmail) {
      this.setErrorMessage('email');
      return;
    } else if (!authentication.loginEmail.includes('@' && '.')) {
      this.setErrorMessage('authemail');
      return;
    } else if (!authentication.loginPassword) {
      this.setErrorMessage('password');
      return;
    } else {
      this.setErrorMessage('');
    }

    try {
      await authentication.authenticate();

      await cart.create();
      await user.loadInfo();

      toggle();

      navigate(0);
    } catch (error) {
      const userInformation =
        authentication.loginEmail && authentication.loginPassword;

      if (
        error.message === 'Request failed with status code 401' &&
        userInformation
      ) {
        this.setErrorMessage('checkemail');
      }
    }
  }

  @action setResetVisibility() {
    this.resetVisible = !this.resetVisible;
  }

  @action setErrorMessage(message) {
    this.message = message;
  }

  render() {
    const { authentication } = this.props;

    return (
      <div className="component-login">
        {!this.resetVisible ? (
          <div
            className="component-login__form"
            onKeyDown={this.handleKeyPress}
          >
            <h3 className="component-login__form__title">
              <Translate component="login" keyword="login-title" />
            </h3>
            <Input
              className={'legacy-form-input'}
              type={'email'}
              name={'loginEmail'}
              keyword={'email'}
              store={authentication}
            />

            <Input
              className={'legacy-form-input'}
              type={'password'}
              name={'loginPassword'}
              keyword={'password'}
              store={authentication}
            />

            <div className="component-login__form__buttons">
              <div
                className="component-login__form__button"
                onClick={this.tryToAuthenticate}
              >
                <p>
                  <Translate component="login" keyword="login-button" />
                </p>
              </div>
              <p
                className="component-login__reset"
                onClick={this.setResetVisibility}
              >
                <Translate component="login" keyword="forgot-message" />
              </p>
            </div>

            <Alert show={!!this.message} type={Type.ERROR}>
              <Translate component="login-error" keyword={this.message} />
            </Alert>
          </div>
        ) : null}

        {this.resetVisible ? (
          <ResetPassword toggle={this.setResetVisibility} />
        ) : null}
      </div>
    );
  }
}

export default Login;
