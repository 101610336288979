import React from 'react';
import Modal from 'react-modal';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import BannersStore from '../../../stores/Banners';
import Newsletter from '../../../stores/Newsletter';
import Image, { ImageData } from '../../Image';
import { Sizes } from '../../../constants/Image';
import './styles/index.scss';
import Translate from '../../Translate/Translate';
import LocalizedLink from '../../LocalizedLink';

interface Props {
  banners?: BannersStore;
  newsletter?: Newsletter;
}

@inject('banners', 'newsletter')
@observer
export default class NewsLetter extends React.Component<Props> {
  @observable modalIsOpen = false;
  @observable newNewsletter = { email: '' };
  @observable singleBanner: { cover: ImageData } = { cover: null };
  @observable confirmation = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.newNewsletter = {
      email: '',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.loadBanners();
  }

  async loadBanners() {
    const { banners } = this.props;
    const banner = await banners.loadBySlug('newsletter-banner');
    this.setBanner(banner);
  }

  async postNewsletter(email) {
    const { newsletter } = this.props;
    const response = await newsletter.postEmail(email);

    if (response.status === 200) {
      this.setConfirmation();
    }
  }

  @action setBanner(banner) {
    this.singleBanner = banner;
  }

  @action setConfirmation() {
    this.confirmation = !this.confirmation;
  }

  @action openModal() {
    this.modalIsOpen = true;
    document.body.style.overflow = 'hidden';
  }

  @action closeModal() {
    document.body.style.overflow = 'visible';
    this.modalIsOpen = false;
  }

  @action onChange(e) {
    this.newNewsletter[e.target.name] = e.target.value;
  }

  @action onSubmit(e) {
    e.preventDefault();
    this.postNewsletter(this.newNewsletter.email);
    this.newNewsletter = {
      email: '',
    };
  }

  confirmMessage() {
    let className = 'newsLetter__info__confirm';
    if (this.confirmation) {
      className += '--visible';
      setTimeout(() => {
        this.setConfirmation();
        this.closeModal();
      }, 3000);
    }
    const message = (
      <div className={className}>
        <svg>
          <path
            d="M43.3,20.5c0.9,0.9,0.9,2.4,0,3.3L27.5,39.5c-0.9,0.9-2.4,0.9-3.3,0L16.7,32c-0.9-0.9-0.9-2.4,0-3.3
	c0.9-0.9,2.4-0.9,3.3,0l5.8,5.8l14.1-14.1C40.9,19.6,42.3,19.6,43.3,20.5z M60,30c0,16.6-13.4,30-30,30C13.4,60,0,46.6,0,30
	C0,13.4,13.4,0,30,0C46.6,0,60,13.4,60,30z M55.3,30C55.3,16,44,4.7,30,4.7C16,4.7,4.7,16,4.7,30C4.7,44,16,55.3,30,55.3
	C44,55.3,55.3,44,55.3,30z"
          />
        </svg>
        <p>
          <Translate component="newsletter" keyword="confirm-message" />
        </p>
      </div>
    );
    return message;
  }

  render() {
    const modal = (
      <Modal
        isOpen={this.modalIsOpen}
        overlayClassName={'newsLetter__overlay'}
        ariaHideApp={false}
        onRequestClose={this.closeModal}
        className="newsLetter"
      >
        <div className="newsLetter__content">
          <div className="newsLetter__banner">
            <Image image={this.singleBanner.cover} defaultSize={Sizes.W356} />
            <div
              onClick={this.confirmation ? undefined : this.closeModal}
              className="newsLetter__exit"
            />
          </div>
          <div className="newsLetter__info">
            <h3>
              <Translate component="newsletter" keyword="title" />
            </h3>
            <p>
              <Translate component="newsletter" keyword="subtext" />
            </p>
            <form onSubmit={this.onSubmit}>
              <label>
                <Translate component="newsletter" keyword="email" />
              </label>
              <input
                required={true}
                onChange={this.onChange}
                name="email"
                type="email"
              />
              <p>
                <Translate component="newsletter" keyword="conditions-text" />
                <LocalizedLink to="/tingimused" onClick={this.closeModal}>
                  <Translate component="newsletter" keyword="conditions-link" />
                </LocalizedLink>
              </p>
              <div className="newsLetter__info__button">
                <button type="submit">
                  <Translate component="newsletter" keyword="button" />
                </button>
              </div>
            </form>
            {this.confirmMessage()}
          </div>
        </div>
      </Modal>
    );

    return (
      <div className="component-newsLetter">
        <h2 className="text-3xl" onClick={this.openModal}>
          <Translate component="footer" keyword="newsletter" />
        </h2>
        {modal}
      </div>
    );
  }
}
