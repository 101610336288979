import Banners from './Banners';
import Blocks from './Blocks';
import Products from './Products';
import Cart from './Cart';
import Checkout from './Checkout';
import Shipping from './Shipping';
import Orders from './Orders';
import Payment from './Payment';
import Authentication from './Authentication';
import User from './User';
import Sorters from './Sorters';
import JobOffers from './JobOffers';
import Translations from './Translations';
import Pages from './Pages';
import Newsletter from './Newsletter';

import LandingPageStore from './Landing';

import Recruit from './Recruit';

export const banners = new Banners();
export const blocks = new Blocks();
export const products = new Products();
export const cart = new Cart();
export const checkout = new Checkout();
export const shipping = new Shipping();
export const orders = new Orders();
export const payment = new Payment();
export const authentication = new Authentication();
export const user = new User();
export const sorters = new Sorters();
export const jobOffers = new JobOffers();
export const translations = new Translations();
export const pages = new Pages();
export const newsletter = new Newsletter();

export const landing = new LandingPageStore();

export const recruit = new Recruit();
