import axios, { AxiosInstance } from 'axios';
import uiConfig from './Config';

const ajax: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: uiConfig.api,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export default ajax;
