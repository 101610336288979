import React, { useContext } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';
import SearchInput from './SearchInput';
import config from '../../services/Config';
import AlgoliaContext from '../../context/AlgoliaContext';
import { Language } from '../../stores/Translations';

const searchClient = algoliasearch(
  config.algoliaAppId,
  config.algoliaSearchKey,
);

const Search2 = () => {
  const algoliaContext = useContext(AlgoliaContext);
  const algoliaIndexName =
    algoliaContext.appLocale === Language.RU
      ? config.algoliaRuSearchIndex
      : config.algoliaSearchIndex;

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={algoliaIndexName}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <SearchInput />
    </InstantSearch>
  );
};

export default Search2;
