import React from 'react';
import { observer, inject } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';
import { userRegistered } from '../../../services/Klaviyo';
import AuthenticationStore from '../../../stores/Authentication';
import CartStore from '../../../stores/Cart';
import UserStore from '../../../stores/User';

import Translate from '../../Translate/Translate';
import LocalizedLink from '../../LocalizedLink';

import Input from '../Input';

import './styles/index.scss';
import Alert, { Type } from '../../../components/Alert';

interface Props {
  navigate: NavigateFunction;
  authentication?: AuthenticationStore;
  cart?: CartStore;
  user?: UserStore;
  toggle: () => void;
}

@inject('authentication', 'cart', 'user')
@observer
class Register extends React.Component<Props> {
  @observable message: string;
  @observable emailErrors = [];
  @observable errorsArea = false;
  @observable passwordErrors = [];
  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.tryToRegister = this.tryToRegister.bind(this);
  }

  async tryToRegister() {
    const { authentication, cart, user, navigate, toggle } = this.props;

    if (!authentication.registerEmail) {
      this.setErrorMessage('email');
      this.emailErrors = [];
      this.passwordErrors = [];
      return;
    } else if (
      !authentication.registerPassword &&
      !authentication.registerPasswordConfirmation
    ) {
      this.setErrorMessage('password');
      this.emailErrors = [];
      this.passwordErrors = [];
      return;
    } else if (!authentication.registerEmail.includes('@' && '.')) {
      this.setErrorMessage('authemail');
      return;
    } else {
      this.setErrorMessage('');
    }

    try {
      await authentication.register();
      await cart.create();
      await user.loadInfo();
      await userRegistered();

      navigate('/toolaud');
      toggle();

      authentication.registerEmail = '';
      authentication.registerPassword = '';
      authentication.registerPasswordConfirmation = '';
    } catch (error) {
      this.setErrors(error);
      this.errorsArea = true;
    }
  }

  @action setErrors(errors) {
    this.emailErrors = errors.email;
    this.passwordErrors = errors.password;
  }

  @action setErrorMessage(message) {
    this.message = message;
  }

  displayErrorMessages() {
    let emailER;
    let passwordER;
    let errorBox;
    let emailMessage;
    let passwordMessage;

    if (this.emailErrors) {
      emailER = this.emailErrors.map((error, index) => {
        if (error === 'The email field is required.') {
          emailMessage = '';
        } else if (error === 'The email has already been taken.') {
          emailMessage = 'email-used';
        } else if (error === 'The email must be a valid email address.') {
          emailMessage = 'authemail';
        } else {
          emailMessage = '';
        }
        return (
          <p key={index}>
            <Translate component="login-error" keyword={emailMessage} />
          </p>
        );
      });
    }

    if (this.passwordErrors) {
      passwordER = this.passwordErrors.map((error, index) => {
        if (error === 'The password field is required.') {
          passwordMessage = '';
        } else if (error === 'The password must be at least 6 characters.') {
          passwordMessage = 'passwordlength';
        } else if (error === 'The password confirmation does not match.') {
          passwordMessage = 'passwordmatch';
        } else {
          emailMessage = '';
        }

        return (
          <p key={index}>
            <Translate component="login-error" keyword={passwordMessage} />
          </p>
        );
      });
    }

    if (this.errorsArea || this.message) {
      errorBox = (
        <Alert show={!!this.errorsArea || !!this.message} type={Type.ERROR}>
          {emailER}
          {passwordER}
          {this.message ? (
            <Translate component="login-error" keyword={this.message} />
          ) : null}
        </Alert>
      );
    }
    return errorBox;
  }

  render() {
    const { authentication, toggle } = this.props;

    return (
      <div className="component-register">
        <h3>
          <Translate component="login" keyword="register-info" />
        </h3>

        <p className="info">
          <Translate component="login" keyword="register-offer" />
        </p>

        <div className="form">
          <div className="form__items">
            <Input
              className={'register-email'}
              type={'text'}
              name={'registerEmail'}
              keyword={'email'}
              store={authentication}
            />
            <Input
              className={'registerPassword'}
              type={'password'}
              name={'registerPassword'}
              keyword={'password'}
              store={authentication}
            />
            <Input
              className={'registerPasswordConfirmation'}
              type={'password'}
              name={'registerPasswordConfirmation'}
              keyword={'password-again'}
              store={authentication}
            />
          </div>
          <p className="terms">
            <Translate component="login" keyword="terms" />
            <LocalizedLink to="/tingimused" onClick={toggle}>
              <Translate component="login" keyword="terms-link" />
            </LocalizedLink>
          </p>
          {this.displayErrorMessages()}
          <button onClick={this.tryToRegister}>
            <Translate component="login" keyword="register-button" />
          </button>
        </div>
      </div>
    );
  }
}

export default Register;
