import React, { useEffect } from 'react';
import Step1 from '../../../images/step1.svg';
import Step2 from '../../../images/step2.svg';
import Step3 from '../../../images/step3.svg';
import Step4 from '../../../images/step4.svg';
import Step5 from '../../../images/step5.svg';
import Stroke from '../../../images/underline-stroke.svg';
import Translate from '../../Translate/Translate';
import LocalizedLink from '../../LocalizedLink';

const ClickAndTry = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="md:m-20 my-10 mx-5">
      <div className="flex mb-12">
        <h4 className="text-3xl leading-10 sm:text-4xl md:text-6xl sm:leading-relaxed md:leading-relaxed font-bold mx-auto text-center">
          Telli tooted koju{' '}
          <div className="sm:flex sm:justify-center">
            <p>proovimiseks </p>
            <div className="sm:ml-4 text-rgreen">
              <div className="inline-block relative">
                <p className="inline-block">TASUTA</p>
                <img
                  src={Stroke}
                  alt="underline brush stroke"
                  className="absolute inset-x-0 w-full md:-mt-3"
                />
              </div>
            </div>
          </div>
        </h4>
      </div>
      <ul
        role="list"
        className="space-y-8 md:space-y-12 xl:w-3/4 md:w-4/5 w-full mx-auto justify-center"
      >
        <div>
          <li className="overflow-hidden bg-white px-6 py-4 md:flex gap-12 items-center w-full">
            <img
              src={Step1}
              alt="Vali tooted"
              className="h-32 w-32 md:h-40 md:w-40"
            />
            <div>
              <p className="text-2xl leading-8 font-axi-bold mb-2">
                Vali tooted
              </p>
              <p className="text-base leading-6 font-axi-book">
                Vali e-poest välja kuni viis toodet ja telli need koju TASUTA
                proovimiseks,{' '}
                <span className="font-axi-bold">v.a. jalgrattad.</span> Tarne
                toimub tavapärase pakiautomaatide ja kullerteenuse hinnakirja
                alusel.
              </p>
            </div>
          </li>
          <li className="overflow-hidden bg-white px-6 py-4 md:flex gap-12 items-center">
            <img
              src={Step2}
              alt="Vormista tellimus"
              className="h-32 w-32 md:h-40 md:w-40"
            />
            <div>
              <p className="text-2xl leading-8 font-axi-bold mb-2">
                Vormista tellimus
              </p>
              <p className="text-base leading-6 font-axi-book">
                Tellimuse vormistamine käib vastavalt tavapärasele e-poe
                tellimusele. Vali proovimiseks välja tooted, lisa need
                ostukorvi, täida kontaktandmete väljad ja{' '}
                <span className="font-axi-bold">
                  makseviiside juurest vali &quot;TELLI JA PROOVI TASUTA&quot;.
                </span>
              </p>
            </div>
          </li>
          <li className="overflow-hidden bg-white px-6 py-4 md:flex gap-12 items-center">
            <img
              src={Step3}
              alt="Kinnitamine"
              className="h-32 w-32 md:h-40 md:w-40"
            />
            <div>
              <p className="text-2xl leading-8 font-axi-bold mb-2">
                Kinnitamine
              </p>
              <p className="text-base leading-6 font-axi-book">
                Enda isiku tuvastamise ja ostu kinnitamise võimalused:{' '}
                <span className="font-axi-bold">
                  Smart-ID, Mobiil-ID või ID-kaart.
                </span>
              </p>
            </div>
          </li>
          <li className="overflow-hidden bg-white px-6 py-4 md:flex gap-12 items-center">
            <img
              src={Step4}
              alt="Teavitus paki saabumisest"
              className="h-32 w-32 md:h-40 md:w-40"
            />
            <div>
              <p className="text-2xl leading-8 font-axi-bold mb-2">
                Teavitus paki saabumisest
              </p>
              <p className="text-base leading-6 font-axi-book">
                Teavituse paki saabumisest saadame{' '}
                <span className="font-axi-bold">e-posti </span>või{' '}
                <span className="font-axi-bold">SMS-i</span>
                teel. Proovimiseks ja mittesobivate toodete tagastamiseks on
                alates kauba kättesaamisest aega 14 päeva.
              </p>
            </div>
          </li>
          <li className="overflow-hidden bg-white px-6 py-4 md:flex gap-12 items-center">
            <img
              src={Step5}
              alt="Maksmine"
              className="h-32 w-32 md:h-40 md:w-40"
            />
            <div>
              <p className="text-2xl leading-8 font-axi-bold mb-2">Maksmine</p>
              <p className="text-base leading-6 font-axi-book">
                Pärast 14 päeva möödumist koostatakse arve nende toodete eest,
                mida te ei ole tagastanud. Maksmiseks arvestatakse 30 päeva
                alates e-poes tellimuse vormistamisest. Makseinfo ja
                meeldetuletused edastatakse e-posti ja SMS-i teel.
              </p>
            </div>
          </li>
        </div>
        <div className="flex justify-center">
          <LocalizedLink
            className="justify-center border border-solid border-rgrey-light rounded transition-all duration-100 font-axi-bold mt-2.5 py-3 px-6 bg-rgreen text-white"
            to={'/tooted'}
            onClick={scrollToTop}
          >
            <Translate component="try" keyword="start-here" />
          </LocalizedLink>
        </div>
      </ul>
    </div>
  );
};
export default ClickAndTry;
