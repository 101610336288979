import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Slider, { LazyLoadTypes } from 'react-slick';
import LocalizedLink from '../LocalizedLink';
import Loader from '../Loader/Loader';

const GET_BRANDS = gql`
  query VisibleBrands {
    visibleBrands {
      id
      title
      slug
      logoUrl
    }
  }
`;

const sliderConfig = {
  className: 'footerBrands__slider',
  lazyLoad: 'ondemand' as LazyLoadTypes,
  slidesToShow: 6,
  slidesToScroll: 4,
  initialSlide: 5,
  infinite: true,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const FooterBrands = () => {
  const { loading, error, data } = useQuery(GET_BRANDS);
  const urlParams = new URLSearchParams();

  if (loading) {
    return (
      <div className="flex justify-center m-4">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const logos = data.visibleBrands.map((brand, id) => {
    urlParams.set('brand', brand.title);

    return (
      <LocalizedLink
        reloadDocument={true}
        to={`/tooted?${urlParams.toString()}`}
        className="flex items-center justify-center w-full h-20 box-border py-0 px-1.5 outline-none"
        key={id}
        label={brand.title}
      >
        <img
          className="invert opacity-70 hover:opacity-100 w-full h-auto max-w-[120px] max-h-[50px]"
          src={brand.logoUrl}
          alt={brand.title}
        />
      </LocalizedLink>
    );
  });

  return (
    <div>
      <Slider {...sliderConfig}>{logos}</Slider>
    </div>
  );
};

export default FooterBrands;
